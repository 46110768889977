import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from '@api/store';
import { createRoot } from 'react-dom/client';
import { App } from '@ui/components';
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

