import { configureStore, ThunkAction, Action, ConfigureStoreOptions } from '@reduxjs/toolkit';

import { userSlice, subscriptionsSlice, reportsSlice, reportsListSlice, patientsSlice, templatesSlice } from './reducers';

export const createStore = (preloadedState?: ConfigureStoreOptions) => configureStore({
  reducer: {
    [userSlice.name]: userSlice.reducer,
    [subscriptionsSlice.name]: subscriptionsSlice.reducer,
    [reportsSlice.name]: reportsSlice.reducer,
    [reportsListSlice.name]: reportsListSlice.reducer,
    [patientsSlice.name]: patientsSlice.reducer,
    [templatesSlice.name]: templatesSlice.reducer
  },
  preloadedState: preloadedState || (globalThis as any).__PRELOADED_STATE__ || {},
  devTools: true
});

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;